const userInfoAuth = JSON.parse(localStorage.getItem("userData"));
let auth = [];
const permission = JSON.parse(localStorage.getItem("userData"))
  ? JSON.parse(localStorage.getItem("userData")).authentication
  : {};

if (userInfoAuth && userInfoAuth.authentication) {
  const authentication = userInfoAuth.authentication;

  if (authentication.CustomersReport !== undefined) {
    auth.push("CustomersReport");
  }

  if (authentication.Master && authentication.Master.length > 0) {
    auth.push("Master");

    const masterAuth = [
      "User_Master",
      "Form_information_master",
      "Banner_master",
      "Category_master",
      "SubCategory_master",
      "Promocode_master",
      "Notification_master",
      "Testimonial_master",
      "Blog_master",
      "Expense_Master",
      "Payment_Master",
      "Manage_Expense_Master"
    ];

    masterAuth.forEach((item) => {
      if (authentication.Master.includes(item)) {
        auth.push(item);
      }
    });
  }

  if (authentication.ProductsMaster !== undefined) {
    auth.push("ProductsMaste");
  }

  if (authentication.ComboProductsMaster !== undefined) {
    auth.push("ComboProducts");
  }

  if (authentication.OrderHistoryMaster !== undefined) {
    auth.push("Order_History_master");
  }

  if (authentication.SettingsMaster !== undefined) {
    auth.push("SettingsMaster");
  }

  if (authentication.PurchaseInventory !== undefined) {
    auth.push("PurchaseInventory");
  }

  if (authentication.ReportMaster && authentication.ReportMaster.length > 0) {
    const reportAuth = ["Report_master", "Sales_master"];

    reportAuth.forEach((item) => {
      if (authentication.ReportMaster.includes(item)) {
        auth.push(item);
      }
    });
  }
}

const MenuItems = [
  {
    menu_title: "Dashboard",
    path: "/app/dashboard",
    icon: "dashboard",
    category: "general",
    isMenuOpen: false,
    menu: "dashboard",
    fullPageMenu: false,
    desc: false,
    content: "",
    child_routes: null,
    permission: true,
  },
  {
    menu_title: "Master",
    path: "/app/master/",
    icon: <i className="fa fa-cube" aria-hidden="true"></i>,
    category: "general",
    isMenuOpen: false,
    menu: "master",
    fullPageMenu: false,
    desc: false,
    content: "",
    permission: auth.includes("Master"),
    child_routes: [
      {
        path: "/app/master/user",
        menu_title: "User Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: auth.includes("User_Master"),
      },
      {
        path: "/app/master/formInformation",
        menu_title: "Form-Information",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: auth.includes("Form_information_master"),
      },
      {
        path: "/app/master/banner",
        menu_title: "Banner",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: auth.includes("Banner_master"),
      },
      {
        path: "/app/master/category",
        menu_title: "Category",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: auth.includes("Category_master"),
      },
      {
        path: "/app/master/expenseCategory",
        menu_title: "Expense Category Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: auth.includes("Expense_Master"),
      },
      {
        path: "/app/master/payment",
        menu_title: "Payment Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: auth.includes("Payment_Master"),
      },
      {
        path: "/app/master/manageExpense",
        menu_title: "Manage Expense Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: auth.includes("Manage_Expense_Master"),
      },
      {
        path: "/app/master/school",
        menu_title: "School Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        // permission: true,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("School_master")
            : false,
      },
      {
        path: "/app/master/import-schools",
        menu_title: "Import schools",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        // permission: true,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Import_schools")
            : false,
      },
      {
        path: "/app/master/city",
        menu_title: "City Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("City_master")
            : false,
      },
      {
        path: "/app/master/brand",
        menu_title: "Brand Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Brand_master")
            : false,
      },
      {
        path: "/app/master/supplier",
        menu_title: "Supplier Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Supplier_master")
            : false,
      },
      // {
      //   path: "/app/master/subCategory",
      //   menu_title: "Sub Category",
      //   icon: "equalizer",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission: auth.includes("SubCategory_master"),
      // },
      // {
      //   path: "/app/master/blogs",
      //   menu_title: "Blogs",
      //   icon: "equalizer",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission: true,
      // },
      {
        path: "/app/master/promocode",
        menu_title: "Promocode",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: auth.includes("Promocode_master"),
      },
      {
        path: "/app/master/notification",
        menu_title: "Notification",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: auth.includes("Notification_master"),
      },
      {
        path: "/app/master/testimonial",
        menu_title: "Testimonial",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: auth.includes("Testimonial_master"),
      },

      // {
      //   path: "/app/master/popup",
      //   menu_title: "Popup Master",
      //   icon: "equalizer",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission: true,
      // },
      {
        path: "/app/master/faq-category",
        menu_title: "FAQ Category",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("FAQ_Category_master")
            : false,
      },

      {
        path: "/app/master/faq",
        menu_title: "FAQ Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("FAQ_master")
            : false,
      },
      {
        path: "/app/master/warehouse",
        menu_title: "Warehouse Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Warehouse_master")
            : false,
      },
    ],
  },
  {
    menu_title: "Customers",
    path: "/app/views/customers",
    icon: <i className="fa fa-user" aria-hidden="true"></i>,
    category: "general",
    isMenuOpen: false,
    menu: "order",
    fullPageMenu: false,
    desc: false,
    content: "",
    permission: auth.includes("CustomersReport"),
    child_routes: null,
  },
  {
    menu_title: "Products",
    path: "/app/master/product",
    icon: <i class="fa fa-folder" aria-hidden="true"></i>,
    category: "general",
    isMenuOpen: false,
    menu: "product",
    fullPageMenu: false,
    desc: false,
    content: "",
    permission: auth.includes("ProductsMaste"),
    child_routes: null,
  },
  {
    menu_title: "Combo Products",
    path: "/app/master/combo-product",
    icon: <i class="fa fa-list" aria-hidden="true"></i>,
    category: "general",
    isMenuOpen: false,
    menu: "product",
    fullPageMenu: false,
    desc: false,
    content: "",
    permission: auth.includes("ComboProducts"),
    child_routes: null,
  },
  // {
  //   menu_title: "Orders History",
  //   path: "/app/views/reports/Orders",
  //   icon: <i className="fa fa-cart-arrow-down" aria-hidden="true"></i>,
  //   category: "general",
  //   isMenuOpen: false,
  //   menu: "order",
  //   fullPageMenu: false,
  //   desc: false,
  //   content: "",
  //   permission: auth.includes("Order_History_master"),
  //   child_routes: null,
  // },
  {
    menu_title: "Warehouse Orders",
    path: "/app/views/reports/warehouse-orders",
    icon: <i className="fa fa-cart-arrow-down" aria-hidden="true"></i>,
    category: "general",
    isMenuOpen: false,
    menu: "order",
    fullPageMenu: false,
    desc: false,
    content: "",
    permission:
      permission?.WarehouseOrdersReport !== undefined
        ? permission.WarehouseOrdersReport.length > 0
        : false,
    child_routes: null,
  },
  {
    menu_title: "Admin Orders",
    path: "/app/views/reports/admin-orders",
    icon: <i className="fa fa-cart-arrow-down" aria-hidden="true"></i>,
    category: "general",
    isMenuOpen: false,
    menu: "order",
    fullPageMenu: false,
    desc: false,
    content: "",
    permission:
      permission?.AdminOrdersReport !== undefined
        ? permission.AdminOrdersReport.length > 0
        : false,
    child_routes: null,
  },
  {
    path: "/app/views/reports/replacement",
    menu_title: "Reverse Orders",
    icon: "published_with_changes",
    category: "general",
    isMenuOpen: false,
    menu: "order",
    fullPageMenu: false,
    desc: false,
    content: "",
    permission:
      permission?.ReverseOrdersReport !== undefined
        ? permission.ReverseOrdersReport.length > 0
        : false,
    child_routes: null,
  },
  {
    path: "/app/views/reports/warehouse-replacement-orders",
    menu_title: "Warehouse Reverse Orders",
    icon: "flip_camera_android",
    category: "general",
    isMenuOpen: false,
    menu: "order",
    fullPageMenu: false,
    desc: false,
    content: "",
    permission:
      permission?.WarehouseReverseOrdersReport !== undefined
        ? permission.WarehouseReverseOrdersReport.length > 0
        : false,
    child_routes: null,
  },
  {
    menu_title: "Reports",
    path: "/app/reports/",
    icon: <i className="fa fa-cube" aria-hidden="true"></i>,
    category: "general",
    isMenuOpen: false,
    menu: "reports",
    fullPageMenu: false,
    desc: false,
    content: "",
    permission: auth.includes("Report_master"),
    child_routes: [
      // {
      //   path: "/app/reports/Sales",
      //   menu_title: "Sales Report",
      //   icon: "equalizer",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission: auth.includes("Sales_master"),
      // },
      {
        path: "/app/reports/demanded-products",
        menu_title: "Demanded Products Report",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        // permission: true
        permission:
          permission && permission.ReportMaster !== undefined
            ? permission &&
            permission.ReportMaster.includes("Demanded_products")
            : false,
      },
      {
        path: "/app/reports/unregistered-schools",
        menu_title: "Unregistered Schools Report",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        // permission: true
        permission:
          permission && permission.ReportMaster !== undefined
            ? permission &&
            permission.ReportMaster.includes("Unregistered_schools")
            : false,
      },
      {
        path: "/app/reports/purchase-logs",
        menu_title: "Purchase Logs Report",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        // permission: true
        permission:
          permission && permission.ReportMaster !== undefined
            ? permission && permission.ReportMaster.includes("Purchase_logs")
            : false,
      },
      {
        path: "/app/reports/abandoned-cart",
        menu_title: "Abandoned Cart Report",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        // permission: true
        permission:
          permission && permission.ReportMaster !== undefined
            ? permission && permission.ReportMaster.includes("Abandoned_cart")
            : false,
      },
      {
        path: "/app/reports/customers",
        menu_title: "Customers Report",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.ReportMaster !== undefined
            ? permission && permission.ReportMaster.includes("Customers")
            : false,
      },
      {
        path: "/app/reports/childrens",
        menu_title: "Childrens Report",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.ReportMaster !== undefined
            ? permission && permission.ReportMaster.includes("Childrens")
            : false,
      },
    ],
  },
  {
    menu_title: "Product Inventory",
    path: "/app/purchase-inventory/",
    icon: <i className="fa fa-cube" aria-hidden="true"></i>,
    category: "inventory",
    isMenuOpen: false,
    menu: "purchase-inventory",
    fullPageMenu: false,
    desc: false,
    content: "",
    permission: auth.includes("PurchaseInventory"),
    child_routes: [
      {
        path: "/app/purchase-inventory/purchase",
        menu_title: "Purchase Inventory",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.PurchaseInventory !== undefined
            ? permission &&
            permission.PurchaseInventory.includes("Purchase_master")
            : false,
      },
      {
        path: "/app/purchase-inventory/purchase-history",
        menu_title: "Purchase History",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.PurchaseInventory !== undefined
            ? permission &&
            permission.PurchaseInventory.includes("Purchase_history")
            : false,
      },
      {
        path: "/app/purchase-inventory/inventory-report",
        menu_title: "Inventory Report",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.PurchaseInventory !== undefined
            ? permission &&
            permission.PurchaseInventory.includes("Inventory_report")
            : false,
      },
    ],
  },
  {
    menu_title: "Settings",
    path: "/app/master/setting",
    icon: <i className="fa fa-cog" aria-hidden="true"></i>,
    category: "general",
    isMenuOpen: false,
    menu: "order",
    fullPageMenu: false,
    desc: false,
    content: "",
    permission: auth.includes("SettingsMaster"),
    child_routes: null,
  },
];

export default MenuItems;
