const initialState = {
  data: [],
  total: 1,
  params: {},
  pincode_data: {},
  searchedProduct: []
}

const promocodeReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_DATA_PROMOCODE':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'CREATE_PROMOCODE':
      return { ...state }
    case 'UPDATE_PROMOCODE':
      return { ...state }

    case 'GET_CITY_BY_PINCODE':
      return {
        ...state,
        pincode_data: action.data
      }
    case "SEARCH_PRODUCTS_VARIATION":
      return {
        ...state,
        searchedProduct: action.data,
      };

    default:
      return { ...state }
  }
}
export default promocodeReducer
